import React from "react";

import { Banner } from "./banner/banner";
// import { Footer } from "./footer/footer";
import { AsSeenOn } from "./as-seen-on/as-seen-on";
import { Newsletter } from "components/newsletter/newsletter";

import styles from "./home.module.scss";

export const HomePage: React.FC = () => {
  return (
    <div className={styles.container}>
      <Banner />
      <div className={styles.newsletterWrapper}>
        <Newsletter>
          <span className={styles.newsletterText}>
            Voted World’s #1 Most Trusted Crypto Expert By More Than 130,000 Independent Analysts.
          </span>
        </Newsletter>
      </div>
      <AsSeenOn />
      <div className={styles.newsletterContent}>
        <Newsletter className={styles.newsletterBackground} />
      </div>
      {/*<Footer />*/}
    </div>
  );
};
